<template>
  <div
    @click="openImage"
    class="message__block message__block--multimedia"
    role="button">
    <img
      :src="previewUrl"
      class="message__block-content"/>
  </div>
</template>

<script>
import {
  CAROUSEL_OPEN,
} from '@/eventTypes';
import EventBus from '@/eventBus';
import { getToken } from '@/vue-apollo';

export default {
  props: {
    message: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      token: getToken(),
    };
  },
  computed: {
    tokenQuery() {
      return `?token=${this.token}`;
    },
    multimedia() {
      return this.message.multimedia ?? {};
    },
    fullUrl() {
      return this.multimedia.file ? `${this.multimedia.file}${this.tokenQuery}` : '';
    },
    thumbUrl() {
      return this.multimedia.thumbnail ? `${this.multimedia.thumbnail}${this.tokenQuery}` : '';
    },
    hasThumbnail() {
      return this.thumbUrl.length > 0;
    },
    previewUrl() {
      return this.hasThumbnail ? this.thumbUrl : this.fullUrl;
    },
  },
  methods: {
    openImage() {
      const payload = {
        url: this.fullUrl,
        type: 'image',
      };

      EventBus.$emit(CAROUSEL_OPEN, payload);
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/chat/_message.scss';
</style>
